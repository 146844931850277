/* (( *** AUTO GEN *** )) */
/* messageActions.js */
import axios from 'axios';
import { MESSAGES_FETCHED, MESSAGE_FETCHED, MESSAGE_CREATED } from '../types';

export function messageFetched(message) {
    return {
      type: MESSAGE_FETCHED,
      message,
    };
  }

  export function messageCreated(message) {
    return {
      type: MESSAGE_CREATED,
      message,
    };
  }

export function setMessages(messages) {
  return {
    type: MESSAGES_FETCHED,
    messages,
  };
}

export const fetchMessage = (id) => async (dispatch) => {
  const res = await axios.get('/api/messages/read/' + id);
  dispatch(messageFetched(res.data));
};
export const fetchMessagesById = (id) => async (dispatch) => {
  const res = await axios.get('/api/messages/read/' + id);
  dispatch(setMessages(res.data));
};

export const fetchMessagesForUser = (senderId) => async (dispatch) => {
  const res = await axios.get('/api/messages/read/senderId/' + senderId);
  dispatch(setMessages(res.data));
};

export const fetchMessagesByRecipientId = (recipientId) => async (dispatch) => {
  //disable for interim check in to LIVE
  // const res = await axios.get('/api/messages/read/recipientId/' + recipientId);
  // dispatch(setMessages(res.data));
};

export const createMessage = (values) => async (dispatch) => {
  const res = await axios.post('/api/messages/create', values);
  dispatch(messageFetched(res.data));
};

export const updateMessage = (values) => async (dispatch) => {
  const res = await axios.post('/api/messages/update', values);
  dispatch(messageFetched(res.data));
};

export const deleteMessage = (values) => async (dispatch) => {
  const res = await axios.post('/api/messages/update', values);
  dispatch(setMessages(res.data));
};
