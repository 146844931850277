import React from 'react';
import JumpPhotoCard from './JumpPhotoCard';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  maxWidth: 850,
  width: '100%',
});

const reorder = (list, startIndex, endIndex, jump, dispatch) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  if (jump) {
    jump.onPhotoMoved(dispatch, result);
  }
};

class JumpPhotosGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoOrder: this.props.jump.properties.photoOrder,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const photoOrder = reorder(
      this.props.jump.properties.photoOrder,
      result.source.index,
      result.destination.index,
      this.props.jump,
      this.props.dispatch
    );

    this.setState({
      photoOrder,
    });
  }

  renderJumpPhotos() {
    if (this.props.jump && this.props.jump.photos) {
      return this.props.jump.photos.map((jumpPhoto, index) => {
        return (
          <div key={jumpPhoto.id + 1000} >
            {this.props.auth ? (
              <Draggable
                key={jumpPhoto.id}
                draggableId={jumpPhoto.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <JumpPhotoCard
                      jumpPhoto={jumpPhoto}
                      onDelete={this.props.onDelete}
                      onMakeMainPhoto={this.props.onMakeMainPhoto}
                      jump={this.props.jump}
                    />
                  </div>
                )}
              </Draggable>
            ) : (
              <JumpPhotoCard
                jumpPhoto={jumpPhoto}
                jump={this.props.jump}
              />
            )}
          </div>
        );
      });
    }
    return null;
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable"> 
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.renderJumpPhotos()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default connect()(JumpPhotosGroup);
