import { SET_EVENTS, EVENT_FETCHED, EVENT_CREATED } from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENT_FETCHED: {
      const updatedData = state.map((item) =>
        item.id === action.event.id ? { ...item, ...action.event } : item
      );
      return updatedData;
    }
    case EVENT_CREATED:
      return [...state, action.event];
    case SET_EVENTS:
      return action.events;
    default:
      return state;
  }
}
