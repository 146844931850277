import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
// import { darken } from 'polished';
// import { Search as SearchIcon } from "react-feather";
// import { useTranslation } from 'react-i18next';
// import LogoutButton from './LogoutButton';
// import LoginButton from './LoginButton';
import NavbarUserDropdown from './NavbarUserDropdown';
import HorseStats from '../../../customers/horses/core/HorseStats';

import {
  Grid,
  // InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  // Stack,
  Toolbar,
} from '@mui/material';

/**
 * xs = iPhone
 * sm = iPad Mini
 * lg = iPad
 * xl = Computer
 */

import { Menu as MenuIcon } from '@mui/icons-material';

// import NavbarNotificationsDropdown from './NavbarNotificationsDropdown';
// import NavbarMessagesDropdown from './NavbarMessagesDropdown';
// import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const auth = useSelector((state) => state.auth);

  function displayAd() {
    return <HorseStats auth={auth} />;
  }

  function checkDisplayAd() {
    if (!auth) {
      return displayAd();
    } else {
      if (auth && (auth.isAdmin() || auth.checkHasPermission('ADD_HORSES'))) {
        return '';
      } else {
        return displayAd();
      }
    }
  }
  // const { t } = useTranslation();
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs // fills space
              // display flex enables justify and align
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
              justifyContent="center"
              alignItems="center"
            >
              {checkDisplayAd()}
            </Grid>
            <Grid
              item
              xs // fills space
              sx={{ display: { xs: 'block', sm: 'none' } }}
            />
            <Grid item justifyContent="flex-end">
              {/* <NavbarMessagesDropdown />
                  <NavbarNotificationsDropdown /> */}
              <NavbarUserDropdown auth={auth} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
