import React from 'react';
import styled from '@emotion/styled';
import HelmetTracker from '../../core/HelmetTracker';

import { useSelector } from 'react-redux';
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Stack,
} from '@mui/material';
import { spacing } from '@mui/system';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

export default function AdminPage() {
  const auth = useSelector((state) => state.auth);
//   const weeklySales = useSelector((state) => state.admin.weeklySales);
//   const dailySales = useSelector((state) => state.admin.dailySales);
//   const salesTotals = useSelector((state) => state.admin.salesTotals);
//   const countsByDayForWeek = useSelector(
//     (state) => state.admin.countsReportByDayForWeek
//   );
//   const viewsByDayForWeek = useSelector(
//     (state) => state.admin.salesViewsByDayForWeek
//   );
//   const allViewsByDayForWeek = useSelector(
//     (state) => state.admin.allSalesViewsByDayForWeek
//   );

//   function salesViewsByDayForWeek() {
//     var dataArray = [];
//     var labels = [];
//     if (viewsByDayForWeek) {
//       dataArray.push(viewsByDayForWeek);
//       labels.push('Individual Sales');
//     }
//     if (allViewsByDayForWeek) {
//       dataArray.push(allViewsByDayForWeek);
//       labels.push('All Sales');
//     }
//     if (countsByDayForWeek) {
//       dataArray.push(countsByDayForWeek);
//       labels.push('All Pages');
//     }
//     return (
//       <Grid container spacing={6}>
//         <Grid item xs={12} sm={12} md={12} lg={3} xl>
//           <DailyLineChart
//             title="Page Views"
//             dailyData={dataArray}
//             dataLabels={labels}
//           />
//         </Grid>
//       </Grid>
//     );
//   }

//   function salesStats() {
//     return (
//       <Grid container spacing={6}>
//         <Grid item xs={12} sm={12} md={6} lg={3} xl>
//           <SalesStats
//             title="Sales Clicks"
//             amount={salesTotals.rollingAllSalesWeek}
//             chip="Weekly"
//             percentagecolor={green[500]}
//           />
//         </Grid>
//         <Grid item xs={12} sm={12} md={6} lg={3} xl>
//           <SalesStats
//             title="Sales Clicks"
//             amount={salesTotals.rollingAllSalesDay}
//             chip="Daily"
//             percentagetext="-14%"
//             percentagecolor={red[500]}
//           />
//         </Grid>
//         <Grid item xs={12} sm={12} md={6} lg={3} xl>
//           <SalesStats
//             title="Sales Views"
//             amount={salesTotals.rollingIndividualSalesWeek}
//             chip="Weekly"
//             percentagetext="+18%"
//             percentagecolor={green[500]}
//           />
//         </Grid>
//         <Grid item xs={12} sm={12} md={6} lg={3} xl>
//           <SalesStats
//             title="Sales Views"
//             amount={salesTotals.rollingIndividualSalesDay}
//             chip="Daily"
//             percentagetext="-9%"
//             percentagecolor={red[500]}
//           />
//         </Grid>
//       </Grid>
//     );
//   }

  function getName() {
    if (auth) {
      return auth.firstName;
    }
  }

  function getGreeting() {
    return 'Welcome back, ' + getName() + '! We missed you ';
  }

  return (
    <Stack>
      <HelmetTracker title="Admin" />

      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Site Stats
          </Typography>
          <Typography variant="subtitle1">
            {getGreeting()}.{' '}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          {/* <PageCountTable pageCounts={weeklySales} title="Weekly Sales" /> */}
        </Grid>
        <Grid item xs={12} lg={8}>
          {/* <PageCountTable pageCounts={dailySales} title="Daily Sales" /> */}
        </Grid>
      </Grid>
    </Stack>
  );
}
