import React, {useEffect} from 'react';
import {
  APIProvider,
  Map,
  Marker,
  MapControl,
  ControlPosition,
  useMap
} from '@vis.gl/react-google-maps';

export default function SimpleVisMap() {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // here you can interact with the imperative maps API
  }, [map]);

  // return <></>;

  return (
    <div>
      <APIProvider apiKey={'AIzaSyArZuZW8SIqRp4ISszhan1ZRq3LpudMp7c'}>
        <Map
          style={{ width: '400px', height: '400px' }}
          defaultCenter={{ lat: 33.57249528288538, lng: -81.58181700000034 }}
          defaultZoom={16}
          //   gestureHandling={'greedy'}
          mapTypeId={'satellite'}
        >
          <MapControl position={ControlPosition.TOP_LEFT}></MapControl>
          <Marker
            position={{ lat: 33.570982923227284, lng: -81.58248397528287 }}
            icon="/images/markers/Start.png"
          />
          <Marker
            position={{ lat: 33.57050233419711, lng: -81.58315576582645 }}
            icon="/images/markers/BNMark1.png"
          />
        </Map>
      </APIProvider>
    </div>
  );
}
