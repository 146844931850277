import React from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const center = {
  lat: 33.57249528288538,
  lng: -81.58181700000034,
};

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyArZuZW8SIqRp4ISszhan1ZRq3LpudMp7c',
  });

  const [map, setMap] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(0);
  const [location, setLocation] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds, 100);
    // map.zoom = 15;

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const doClick = (e) => {
    // console.log(e.latLng.lat());
    // console.log(e.latLng.lng());
    console.log(e);
  };

  const doMarkerClick = (event, id) => {
    // console.log(e.latLng.lat());
    // console.log(e.latLng.lng());
    console.log(event);
    console.log(id);
    setSelectedId(id);
    console.log(navigator.geolocation);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(pos);
          console.log(pos);
          // infoWindow.setPosition(pos);
          // infoWindow.setContent("Location found.");
          // infoWindow.open(map);
          map.setCenter(pos);
        },
        () => {
          // handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } 
    else {
      // setLocation(null);
    }
  };

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        defaultZoom={10}
        defaultCenter={center}
        onLoad={onLoad}
        onClick={doClick}
          // onUnmount={onUnmount}
        mapTypeId={'satellite'}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <Marker
          position={{ lat: 33.570982923227284, lng: -81.58248397528287 }}
          icon="/images/markers/Start.png"
          onClick={(event) => doMarkerClick(event, 4)}
        />
        <Marker
          position={{ lat: 33.57050233419711, lng: -81.58315576582645 }}
          icon="/images/markers/BNMark1.png"
          onClick={(event) => doMarkerClick(event, 1)}
        />
        <Polyline
          path={[
            { lat: 33.570982923227284, lng: -81.58248397528287 },
            { lat: 33.57050233419711, lng: -81.58315576582645 },
          ]}
          onClick={(event) => doMarkerClick(event, 3)}
        />
        <></>
      </GoogleMap>
      {selectedId ? <h3>{selectedId}</h3> : ''}
      {location ? <h3>{location}</h3> : 'no loc'}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
