import { MESSAGES_FETCHED, MESSAGE_FETCHED, MESSAGE_CREATED } from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    // NOTE TO FUTURE SELF: 
    // This REQUIRES MESSAGES to be FETCHED ahead of time
    // If it is not already fetched, it has nothing to update.  
    case MESSAGE_FETCHED: {
      const updatedData = state.map((item) =>
        item.id === action.message.id ? { ...item, ...action.message } : item
      );
      return updatedData;
    }
    case MESSAGE_CREATED:
      return [...state, action.message];
    case MESSAGES_FETCHED:
      return action.messages;
    default:
      return state;
  }
}
