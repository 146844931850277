import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import HorseStatsDisplay from '../../components/customers/horses/core/HorseStatsDisplay';
import { Shop } from 'react-bootstrap-icons';
import { Alert, InputGroup } from 'react-bootstrap';
import { Box, Button, Checkbox, Grid, Stack } from '@mui/material';
import { horsesToClassArray } from '../../redux/classes/Horse';
import FavoriteHorses from '../users/core/FavoriteHorses';
import HelmetTracker from '../core/HelmetTracker';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

export default function SalesHorseList({ auth }) {
  const saleshorses = useSelector((state) => state.saleshorses);
  // const auth = useSelector((state) => state.auth);

  const [priceOrder, setPriceOrder] = useState(0);
  const [pedigreeFilterText, setPedigreeFilterText] = useState('');
  const [nameFilterText, setNameFilterText] = useState('');
  const [sortFavorites, setSortFavorites] = useState(false);

  // for BottomNavigation
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);

  const favoriteHorses = useSelector((state) =>
    state.saleshorses.filter((x) => auth && auth.isFavorite(x.id))
  );

  function handleNameFilterTextChange(e) {
    if (e.target) setNameFilterText(e.target.value);
  }

  function handlePedigreeFilterTextChange(e) {
    if (e.target) setPedigreeFilterText(e.target.value);
  }

  function handleFavoritesCheckClick() {
    console.log(favoriteHorses);
    setSortFavorites(!sortFavorites);
  }

  function filterHorsesByPedigree() {
    // Gets hard copy and mutable version of list.
    var horseList = horsesToClassArray(JSON.parse(JSON.stringify(saleshorses)));
    if (pedigreeFilterText.length > 0) {
      let filter = pedigreeFilterText;
      let filteredTextHorses = _.filter(horseList, function (horse) {
        if (horse.pedigree) {
          var searchStr = JSON.stringify(horse.pedigree)
            .replace(/([^\w ]|_)/g, '')
            .toLowerCase();
          var filterstr = filter.replace(/([^\w ]|_)/g, '').toLowerCase();
          return searchStr.includes(filterstr);
        }
      });
      return filteredTextHorses;
    } else return horseList;
  }

  function filterHorsesByName(horseList) {
    // Gets hard copy and mutable version of list.
    if (nameFilterText.length > 0) {
      let filteredTextHorses = _.filter(horseList, function (horse) {
        if (horse.name) {
          var searchStr = JSON.stringify(horse.name)
            .replace(/([^\w ]|_)/g, '')
            .toLowerCase();
          var filterstr = nameFilterText.replace(/([^\w ]|_)/g, '').toLowerCase();
          return searchStr.includes(filterstr);
        }
      });
      return filteredTextHorses;
    } else return horseList;
  }

  function getFavoriteInfoForHorse(horse) {
    return _.find(auth.favorites, function (o) {
      return o.horseId === horse.id;
    });
  }

  function favorited(horse) {
    if (auth) {
      if (auth.favorites) {
        if (getFavoriteInfoForHorse(horse)) {
          return true;
        }
      }
    }
    return false;
  }

  function orderListByFavorites(listToOrder) {
    if (auth) {
      _.forEach(listToOrder, (horse) => {
        if (favorited(horse)) {
          horse.favorited = 1;
          horse.favoriteInfo = getFavoriteInfoForHorse(horse);
        } else {
          horse.favorited = 0;
        }
      });
    }
    var returnList = _.orderBy(listToOrder, ['favorited'], ['desc']);
    return returnList;
  }

  function setFavoritesOnly(listToOrder) {
    if (auth) {
      _.forEach(listToOrder, (horse) => {
        if (favorited(horse)) {
          horse.favorited = 1;
          horse.favoriteInfo = getFavoriteInfoForHorse(horse);
        } else {
          horse.favorited = 0;
        }
      });
    }
    return listToOrder;
  }

  function renderHorses(order) {
    var horseList = filterHorsesByPedigree();
    horseList = filterHorsesByName(horseList);
    var sortArray = [];
    var ascArray = [];
    var descArray = [];
    if (sortFavorites) {
      horseList = orderListByFavorites(horseList);
      sortArray = ['favorited', 'price'];
      ascArray = ['desc', 'asc'];
      descArray = ['desc', 'desc'];
    } else {
      horseList = setFavoritesOnly(horseList);
      sortArray = ['price'];
      ascArray = ['asc'];
      descArray = ['desc'];
    }
    if (order === 0) {
      return _.values(horseList).map((horse) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} key={horse.id}>
            <HorseStatsDisplay horse={horse} auth={auth} />
          </Grid>
        );
      });
    }
    if (order === 1) {
      return _.orderBy(horseList, sortArray, ascArray).map((horse) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} key={horse.id}>
            <HorseStatsDisplay horse={horse} auth={auth} />
          </Grid>
        );
      });
    }
    if (order === 2) {
      var orderedArray = _.orderBy(horseList, sortArray, descArray);
      return orderedArray.map((horse) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} key={horse.id}>
            <HorseStatsDisplay horse={horse} auth={auth} />
          </Grid>
        );
      });
    }
  }

  function changePriceSort() {
    var price = priceOrder;
    price = price + 1;
    if (price > 2) {
      price = 0;
    }
    setPriceOrder(price);
  }

  function priceOrderToText() {
    switch (priceOrder) {
      case 0:
        return 'No sort';
      case 1:
        return 'Ascend';
      case 2:
        return 'Descend';
      default:
        return 'Unk';
    }
  }

  const userId = auth ? auth.id : null;

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <HelmetTracker title={'Sales'} userId={userId} />
      <CssBaseline />
      <Alert variant="primary">
        <h2>
          <Shop />
          &nbsp;&nbsp; Sales
        </h2>
      </Alert>
      {value === 0 ? (
        <InputGroup>
          <Stack spacing={2}>
            <label>
              Horse Name:&nbsp;&nbsp;&nbsp;
              <input
                type="text"
                value={nameFilterText}
                onChange={handleNameFilterTextChange}
              />{' '}
            </label>
            <label>
              Pedigree Includes:&nbsp;&nbsp;&nbsp;
              <input
                type="text"
                value={pedigreeFilterText}
                onChange={handlePedigreeFilterTextChange}
              />{' '}
            </label>
            <label>
              Price Sort:&nbsp;&nbsp;&nbsp;
              <Button variant="contained" onClick={changePriceSort}>
                {priceOrderToText()}
              </Button>
            </label>
            <label>
              Sort Favorites:&nbsp;&nbsp;&nbsp;
              <Checkbox
                checked={sortFavorites}
                onClick={handleFavoritesCheckClick}
              />
            </label>
          </Stack>
        </InputGroup>
      ) : (
        ''
      )}
      <Grid container rowSpacing={6}>
        {value === 0 ? renderHorses(priceOrder) : null}
        {value === 1 ? (
          <FavoriteHorses
            horses={favoriteHorses}
            sale={true}
            auth={auth}
            key={0}
          />
        ) : null}
      </Grid>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="All" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
          <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
