import React, { useState } from 'react';
import _ from 'lodash';

import Papa from 'papaparse';

const EEUploader = () => {
  const [contactsFile, setContactsFile] = useState(null);
  const [competitorsFile, setCompetitorsFile] = useState(null);

  const [contactsData, setContactsData] = useState({});
  const [competitorsData, setCompetitorsData] = useState({});
  const [competitorsPartitionedData, setCompetitorsPartitionedData] = useState(
    {}
  );

  function removeBlankKeys(obj) {
    const newObj = Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === 'string' && value.trim() !== '') {
        acc[key] = value;
      } else if (typeof value !== 'string') {
        acc[key] = value;
      }
      return acc;
    }, {});
    return newObj;
  }

  function removeBlankKeysArray(arrayIn) {
    if(arrayIn) {
      return arrayIn.map((o) => removeBlankKeys(o));
    }
  }

  const parseContactsData = async (file) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var info = Papa.parse(reader.result, { header: true });
      console.log('Contacts');
      // console.log(info);
      console.log(removeBlankKeysArray(info.data));
      setContactsData(removeBlankKeysArray(info.data));
    };
    reader.readAsText(file);
  };

  const parseCompetitorsData = async (file) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var info = Papa.parse(reader.result, { header: true });
      console.log('Competitors');
      console.log(info);
      setCompetitorsData(info);
      // var partitioned = _.partition(info.data, function (o) {
      //   return o.Division;
      // });
      var partitioned = info.data.reduce(function (r, a) {
        if (a.Pinney !== 'HEADER') {
          r[a.Division] = r[a.Division] || [];
          // var obj = _.omitBy(a, false);
          r[a.Division].push(removeBlankKeys(a));
          return r;
        } else return r;
      }, Object.create(null));
      console.log('Partitioned');
      console.log(partitioned);
      setCompetitorsPartitionedData(partitioned);
    };
    reader.readAsText(file);
  };

  const handleContactsFileChange = (e) => {
    if (e.target.files) {
      setContactsFile(e.target.files[0]);
      parseContactsData(e.target.files[0]);
    }
  };

  const handleCompetitorsFileChange = (e) => {
    if (e.target.files) {
      setCompetitorsFile(e.target.files[0]);
      parseCompetitorsData(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    // var reader = new FileReader();
    // reader.onload = function (e) {
    //   var info = Papa.parse(reader.result, {header: true});
    //   console.log(info);
    // };
    // reader.readAsText(file);
  };

  return (
    <>
      <div>
        <label htmlFor="contactsFile" className="sr-only">
          Choose a contactsFile
        </label>
        <input
          id="contactsFile"
          type="file"
          onChange={handleContactsFileChange}
        />
      </div>
      {contactsFile && (
        <section>
          ContactsFile details:
          <ul>
            <li>Name: {contactsFile.name}</li>
            <li>Type: {contactsFile.type}</li>
            <li>Size: {contactsFile.size} bytes</li>
          </ul>
        </section>
      )}
      <div>
        <label htmlFor="competitorsFile" className="sr-only">
          Choose a competitorsFile
        </label>
        <input
          id="competitorsFile"
          type="file"
          onChange={handleCompetitorsFileChange}
        />
      </div>
      {competitorsFile && (
        <section>
          CompetitorsFile details:
          <ul>
            <li>Name: {competitorsFile.name}</li>
            <li>Type: {competitorsFile.type}</li>
            <li>Size: {competitorsFile.size} bytes</li>
          </ul>
        </section>
      )}

      {/* {!_.isEmpty(contactsData) && <div>{JSON.stringify(contactsData)}</div>} */}

      {competitorsFile && contactsFile && (
        <button onClick={handleUpload}>Upload a file</button>
      )}
    </>
  );
};

export default EEUploader;
